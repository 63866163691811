.parallax {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    perspective: 10px;
}

.commission-calculator {
    @media screen and (max-width: 650px) {
        .title {
            text-align: center !important;
        }
    }

    .calculator {
        background-color: var(--backgroundColor02);
        padding: 25px;
        border-radius: 10px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
        margin: 20px 0;

        @media screen and (max-width: 650px) {
            border-radius: 0;
        }
        @media screen and (max-width: 800px) {
            .box-01 {
                grid-template-columns: 1fr !important;
            }
        }

        .box-01 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }
        .panel {
            border-radius: 10px;
            background-color: var(--backgroundColor03);
            width: 100%;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        }
    }
}

.toggle-checkbox {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 10px;

    input {
        display: none;

        &:checked + .slider {
            background-color: $colorSuccess;
            .icon {
                opacity: 1;
            }
        }
    }

    .slider {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 20px;
        height: 20px;
        background-color: var(--backgroundColor05);
        transition: 0.4s;
        border-radius: 3px;
        .icon {
            transition: 0.4s;
            opacity: 0;
            color: white;
            font-size: 1.2rem;
        }
    }
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
    margin: 0 10px;

    input {
        display: none;

        &:checked + .slider {
            background-color: $colorSuccess;
            &::before {
                left: 32.5px;
            }
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        width: 100%;
        height: 100%;
        background-color: var(--backgroundColor05);
        transition: 0.4s;
        border-radius: 50px;

        &::before {
            position: absolute;
            content: "";
            height: 23px;
            width: 23px;
            left: 4.5px;
            bottom: 3.5px;
            background-color: white;
            border-radius: 50px;
            transition: 0.4s;
        }
    }
}

.bubbles {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    transform-style: preserve-3d;
    z-index: -1;
    position: absolute;

    .bubbles-01 {
        transform: translateZ(-10px) scale(2);
    }
    .bubbles-02 {
        transform: translateZ(-5px) scale(1.5);
    }
    .bubbles-01,
    .bubbles-02 {
        position: absolute;
        z-index: -1;
        height: 100%;
        width: 100%;
    }
}

.creation-card {
    max-width: 250px;
    background-color: var(--backgroundColor03);
    color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    transition: 0.3s;
    position: relative;
    cursor: pointer;

    &:hover {
        transform: translateY(-5px);
    }

    .icon {
        position: absolute;
        z-index: 1;
        font-size: 27px;
        top: 10px;
        right: 10px;
        display: grid;
        gap: 5px;

        div {
            background-color: var(--backgroundColor02);
            padding: 6px;
            border-radius: 4px;
        }
    }

    .price {
        width: 120px;
        margin: 0 auto;
        text-align: center;
        background-color: var(--backgroundColor02);
        border-radius: 15px;
        margin-top: 10px;
    }

    .image {
        margin: 0 auto;
        width: 120px;
        height: 120px;
        overflow: hidden; /* This will hide any parts of the image that exceed the container size */
        border-radius: 15px; /* This will make the container round */
        display: flex; /* This will center the image horizontally and vertically */
        justify-content: center;
        align-items: center;
        background-color: var(--backgroundColor02);
        margin-top: 20px;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;

        img {
            object-fit: cover; /* This will scale the image up or down to completely cover the container */
            object-position: center; /* This will center the image within the container */

            &.landscape {
                width: auto;
                height: 100%;
            }
            &.portrait {
                width: 100%;
                height: auto;
            }
        }
    }

    .details {
        padding: 1rem;
        padding-top: 0;

        .name {
            font-family: "Archivo Black", sans-serif;
            font-size: 1.5rem;
            text-align: center;
            margin-bottom: 5px;
        }

        .description {
            opacity: 0.8;
        }
    }
}

.staff-card {
    max-width: 350px;
    background-color: var(--backgroundColor03);
    color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    transition: 0.3s;
    position: relative;

    &:hover {
        transform: translateY(-5px);
    }

    .connect {
        position: absolute;
        z-index: 1;
        font-size: 27px;
        background-color: var(--backgroundColor02);
        padding: 6px;
        top: 10px;
        right: 10px;
        border-radius: 4px;
    }

    .images {
        position: relative;
        .banner {
            width: 350px;
            height: 140px;
            overflow: hidden; /* This will hide any parts of the image that exceed the container size */
            display: flex; /* This will center the image horizontally and vertically */
            justify-content: center;
            align-items: center;

            img {
                object-fit: cover; /* This will scale the image up or down to completely cover the container */
                object-position: center; /* This will center the image within the container */
                height: 100%;
                width: 100%;
            }
        }
        .avatar {
            position: absolute;
            width: 120px;
            height: 120px;
            overflow: hidden; /* This will hide any parts of the image that exceed the container size */
            border-radius: 50%; /* This will make the container round */
            display: flex; /* This will center the image horizontally and vertically */
            justify-content: center;
            align-items: center;
            top: 50%;
            right: 8%;
            box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;

            img {
                object-fit: cover; /* This will scale the image up or down to completely cover the container */
                object-position: center; /* This will center the image within the container */
                border-radius: 50%; /* This will make the image round */
                height: 100%;
                width: 100%;
            }
        }
    }

    .details {
        padding: 1rem;

        .name {
            font-family: "Archivo Black", sans-serif;
            font-size: 1.7rem;
            line-height: 30px;
        }

        .username {
            opacity: 0.5;
        }

        .roles {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            margin-top: 5px;

            .role {
                background-color: var(--backgroundColor02);
                padding: 2px 12px;
                border-radius: 5px;
                cursor: default;
            }
        }
    }
}

.tooltip {
    background-color: black;
    color: white;
    padding: 6px 12px;
    border-radius: 4px;
    max-width: 300px;
}

.drop-shadow-01 {
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.button {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    button {
        display: inline-flex;
        border-radius: 5px;
        border: none;
        outline: none;
        font-size: 1rem;
        font-weight: 500;
        transition: $transition02;
        cursor: pointer;
        overflow: hidden;
        color: white;
        width: fit-content;
        border: 3px solid transparent;

        &.color-theme {
            color: var(--textColor01) !important;
        }

        &.shadow {
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
        }

        &.lock-black {
            color: black;
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        &.full {
            width: 100%;
            justify-content: center;
        }

        &.color-link {
            padding: 0;
            .button-text,
            .button-icon {
                padding: 10px 5px;
            }
        }

        &.color-primary {
            background-color: $buttonColorPrimary;
        }
        &.color-secondary {
            background-color: $buttonColorSecondary;
        }
        &.color-success {
            background-color: $buttonColorSuccess;
        }
        &.color-danger {
            background-color: $buttonColorDanger;
        }
        &.color-blurple {
            background-color: $buttonColorBlurple;
        }
        &.color-gold {
            background-color: $buttonColorGold;
        }

        .button-text,
        .button-icon {
            display: inline-flex;
            align-items: center;
            padding: 7px 13px;
            height: 100%;
            &.both {
                padding-right: 0;
            }
            &.both-1 {
                padding-left: 0;
            }
        }
        .button-icon {
            font-size: 1.5rem;
            &.small {
                font-size: 1.3rem;
            }
        }

        &.hover03 {
            color: var(--textColor01);
            background-color: transparent;

            &.color-primary {
                border-color: $buttonColorPrimary;
            }
            &.color-secondary {
                border-color: $buttonColorSecondary;
            }
            &.color-success {
                border-color: $buttonColorSuccess;
            }
            &.color-danger {
                border-color: $buttonColorDanger;
            }
            &.color-blurple {
                border-color: $buttonColorBlurple;
            }
            &.color-gold {
                border-color: $buttonColorGold;
            }
        }

        &:hover {
            &.color-link {
                border-bottom: 1px solid $colorWhite;
                border-radius: 0;
            }
            &.hover01 {
                color: var(--textColor01);
                background-color: transparent;
                &.full {
                    width: calc(100% - 5px) !important;
                    height: calc(100% - 3px) !important;
                }

                &.color-primary {
                    border-color: $buttonColorPrimary;
                }
                &.color-secondary {
                    border-color: $buttonColorSecondary;
                }
                &.color-success {
                    border-color: $buttonColorSuccess;
                }
                &.color-danger {
                    border-color: $buttonColorDanger;
                }
                &.color-blurple {
                    border-color: $buttonColorBlurple;
                }
                &.color-gold {
                    border-color: $buttonColorGold;
                }
            }

            &.hover02 {
                &.color-primary {
                    background-color: darken($buttonColorPrimary, 5%);
                }
                &.color-secondary {
                    background-color: darken($buttonColorSecondary, 10%);
                }
                &.color-success {
                    background-color: darken($buttonColorSuccess, 10%);
                }
                &.color-danger {
                    background-color: darken($buttonColorDanger, 15%);
                }
                &.color-blurple {
                    background-color: darken($buttonColorBlurple, 5%);
                }
                &.color-gold {
                    background-color: darken($buttonColorGold, 5%);
                }
            }

            &.hover03 {
                color: white;
                &.color-primary {
                    background-color: $buttonColorPrimary;
                }
                &.color-secondary {
                    background-color: $buttonColorSecondary;
                }
                &.color-success {
                    background-color: $buttonColorSuccess;
                }
                &.color-danger {
                    background-color: $buttonColorDanger;
                }
                &.color-blurple {
                    background-color: $buttonColorBlurple;
                }
                &.color-gold {
                    background-color: $buttonColorGold;
                }
            }
        }
    }
}

.footer {
    position: relative;
    width: 100%;
    background-color: $backgroundColor02;
    border-radius: 8px 8px 0 0;
    transition: $transition03;
    margin-top: auto;

    .footer-container {
        max-width: 1540px;
        padding: 30px 40px;
        margin: auto;
    }

    .content-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        .profil {
            .logo-area {
                display: flex;
                align-items: center;
                margin-bottom: 1rem;

                img {
                    width: 40px;
                    height: 40px;
                }

                .logo-name {
                    font-size: 1.35rem;
                    font-family: "Bungee", sans-serif;
                    margin: 0 1rem;
                    color: $colorWhite;
                }

                .theme-toggle {
                    opacity: 0;
                    position: absolute;
                }

                .theme-toggle:checked + .theme-toggle-label .ball {
                    transform: translateX(24px);
                }

                .theme-toggle-label {
                    position: relative;
                    display: flex;
                    width: 50px;
                    height: 26px;
                    background-color: $colorBlack;
                    border-radius: 25px;
                    padding: 5px;
                    align-items: center;
                    justify-content: space-between;

                    .moon {
                        color: $colorYellow01;
                    }

                    .sun {
                        color: $colorOrange01;
                    }

                    .ball {
                        position: absolute;
                        background-color: $colorWhite;
                        width: 22px;
                        height: 22px;
                        border-radius: 25px;
                        top: 2px;
                        left: 2px;
                        transition: $transition02;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .description-area {
                max-width: 320px;
                margin-bottom: 1.25rem;

                p {
                    color: transparentize($colorWhite, 0.5);
                    font-weight: 400;
                    line-height: 2rem;
                }
            }

            .social-media {
                a {
                    color: transparentize($colorWhite, 0.25);
                    margin-right: 22px;
                    font-size: 24px;
                    transition: $transition02;

                    &:hover {
                        color: $colorPurple01;
                    }
                }
            }
        }

        .service-area {
            display: flex;

            .service-header {
                margin-right: 40px;
                list-style: none;

                .service-name {
                    color: $colorWhite;
                    font-family: "Archivo Black", sans-serif;
                }

                li {
                    margin-bottom: 15px;

                    &.disabled {
                        opacity: 0.5;
                        pointer-events: none;
                    }
                    a {
                        color: transparentize($colorWhite, 0.25);
                        padding-bottom: 1px;
                        text-decoration: none;
                        transition: $transition02;
                        font-weight: 400;

                        &:hover {
                            color: $colorPurple01;
                        }
                    }
                }
            }
        }
    }

    hr {
        height: 1px;
        border: none;
        background-color: transparentize($colorWhite, 0.7);
        margin: 0 2rem;
        margin-bottom: 1rem;
    }

    .footer-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.9rem;

        .copyright {
            color: transparentize($colorWhite, 0.25);
            display: flex;
            align-items: center;

            span {
                margin-left: 8px;
                letter-spacing: 0.1rem;
            }
        }

        .tou {
            a {
                color: transparentize($colorWhite, 0.25);
                margin-left: 1.5rem;
                text-decoration: none;
                transition: $transition02;
                font-weight: 400;

                &:hover {
                    color: $colorPurple01;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .content-footer {
            flex-wrap: wrap;
            justify-content: left;
        }

        .service-area {
            flex-wrap: wrap;
            justify-content: left;
        }

        .service-header {
            margin-bottom: 10px;
            margin-left: 0;
            margin-right: 40px;
        }

        .social-media {
            margin-bottom: 1rem;
        }

        .footer-bottom {
            flex-wrap: wrap;
            justify-content: center;

            .copyright {
                margin-bottom: 15px;
            }
        }
    }
}
