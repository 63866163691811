@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=Maven+Pro");
@import url("https://fonts.googleapis.com/css2?family=Bungee");
@import url("https://fonts.googleapis.com/css2?family=Odibee+Sans");
@import url("https://fonts.googleapis.com/css2?family=Play");
@import url("https://fonts.googleapis.com/css2?family=Roboto");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  --backgroundColor01: #11111B;
  --backgroundColor02: #1E1E2E;
  --backgroundColor03: #313149;
  --backgroundColor04: #dadada;
  --backgroundColor05: #605052;
  --backgroundColor06: #1418207c;
  --tableBackgroundColor01: #141820;
  --textColor01: #ffffff;
  --textColorFaded01: #ffffff88;
  --textColor02: #000000;
  --textCodeBackground: #0c0e13;
  --textError01: #ffb3b3;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*::-webkit-scrollbar {
  width: 8px;
  background-color: #0e1218;
  border-radius: 0 8px 8px 0;
}
*::-webkit-scrollbar-thumb {
  background-color: #8c82ce;
  border-radius: 4px 8px 8px 4px;
}

html {
  height: 100%;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100vw;
  min-height: 100vh;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: var(--backgroundColor01) !important;
  transition: 0.5s;
}
body #root {
  z-index: 1;
}
body #portal {
  z-index: 2;
}
body #loading-root {
  position: fixed;
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
  transition: 0.3s;
}
body #loading-root img {
  height: 150px;
  width: 150px;
}
body.load {
  overflow: hidden;
}
body.load #root {
  pointer-events: none !important;
}
body.load #portal {
  pointer-events: none !important;
}
body.load #loading-root {
  pointer-events: none !important;
  opacity: 1;
  z-index: 999;
}
body.load #loading-root img {
  visibility: visible !important;
}
body .text {
  color: var(--textColor01);
}
body .text.error {
  color: var(--textError01);
}
body .text.font-01 {
  font-family: "Bungee", sans-serif;
}
body .text.font-02 {
  font-family: "Archivo Black", sans-serif;
}
body .text.title-404 {
  font-size: 7rem;
  font-weight: 400;
  font-family: "Bungee", sans-serif;
  line-height: 100px;
}
body .text.main-title-1 {
  font-size: 4vw;
  font-weight: 400;
  font-family: "Archivo Black", sans-serif;
}
body .text.main-title-2 {
  font-size: 5rem;
  font-weight: 400;
  font-family: "Bungee", sans-serif;
}
body .text.title-1 {
  font-size: 7rem;
  font-weight: 600;
  font-family: "Archivo Black", sans-serif;
  margin: 10px;
}
body .text.title-2 {
  font-size: 6rem;
  font-weight: 600;
  font-family: "Archivo Black", sans-serif;
  margin: 10px;
}
body .text.title-3 {
  font-size: 5rem;
  font-weight: 600;
  font-family: "Archivo Black", sans-serif;
  margin: 10px;
}
body .text.title-4 {
  font-size: 4rem;
  font-weight: 600;
  font-family: "Archivo Black", sans-serif;
  margin: 10px;
}
body .text.title-5 {
  font-size: 3rem;
  font-weight: 600;
  font-family: "Archivo Black", sans-serif;
  margin: 10px;
}
body .text.title-6 {
  font-size: 2rem;
  font-weight: 600;
  font-family: "Archivo Black", sans-serif;
  margin: 5px;
}
body .text.title-7 {
  font-size: 1.65rem;
  font-weight: 600;
  font-family: "Archivo Black", sans-serif;
  margin: 5px;
}
body .text.title-8 {
  font-size: 1.4rem;
  font-weight: 600;
  font-family: "Archivo Black", sans-serif;
  margin: 5px;
}
body .text.title-9 {
  font-size: 1.25rem;
  font-weight: 600;
  font-family: "Archivo Black", sans-serif;
  margin: 5px;
}
body .text.title-10 {
  font-size: 1rem;
  font-weight: 600;
  font-family: "Archivo Black", sans-serif;
  margin: 5px;
}
body .text.subtitle-1 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 5px;
}
body .text.subtitle-2 {
  font-size: 1.25rem;
  font-weight: 400;
  margin: 5px;
}
body .text.subtitle-3 {
  font-size: 1.15rem;
  font-weight: 400;
  margin: 5px;
}
body .text.subtitle-4 {
  font-size: 1rem;
  font-weight: 400;
  margin: 5px;
}
body .text.text-glitch::before, body .text.text-glitch::after {
  position: absolute;
  content: attr(data-text);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
body .text.text-glitch::before {
  left: 3px;
  text-shadow: -3px 0 red;
  animation: glitch-1 2s linear infinite reverse;
}
body .text.text-glitch::after {
  left: -3px;
  text-shadow: 3px 0 blue;
  animation: glitch-2 3s linear infinite reverse;
}
@keyframes glitch-1 {
  0% {
    clip: rect(132px, auto, 101px, 30px);
  }
  5% {
    clip: rect(17px, auto, 94px, 30px);
  }
  10% {
    clip: rect(40px, auto, 66px, 30px);
  }
  15% {
    clip: rect(87px, auto, 82px, 30px);
  }
  20% {
    clip: rect(137px, auto, 61px, 30px);
  }
  25% {
    clip: rect(34px, auto, 14px, 30px);
  }
  30% {
    clip: rect(133px, auto, 74px, 30px);
  }
  35% {
    clip: rect(76px, auto, 107px, 30px);
  }
  40% {
    clip: rect(59px, auto, 130px, 30px);
  }
  45% {
    clip: rect(29px, auto, 84px, 30px);
  }
  50% {
    clip: rect(22px, auto, 67px, 30px);
  }
  55% {
    clip: rect(67px, auto, 62px, 30px);
  }
  60% {
    clip: rect(10px, auto, 105px, 30px);
  }
  65% {
    clip: rect(78px, auto, 115px, 30px);
  }
  70% {
    clip: rect(105px, auto, 13px, 30px);
  }
  75% {
    clip: rect(15px, auto, 75px, 30px);
  }
  80% {
    clip: rect(66px, auto, 39px, 30px);
  }
  85% {
    clip: rect(133px, auto, 73px, 30px);
  }
  90% {
    clip: rect(36px, auto, 128px, 30px);
  }
  95% {
    clip: rect(68px, auto, 103px, 30px);
  }
  100% {
    clip: rect(14px, auto, 100px, 30px);
  }
}
@keyframes glitch-2 {
  0% {
    clip: rect(129px, auto, 36px, 30px);
  }
  5% {
    clip: rect(36px, auto, 4px, 30px);
  }
  10% {
    clip: rect(85px, auto, 66px, 30px);
  }
  15% {
    clip: rect(91px, auto, 91px, 30px);
  }
  20% {
    clip: rect(148px, auto, 138px, 30px);
  }
  25% {
    clip: rect(38px, auto, 122px, 30px);
  }
  30% {
    clip: rect(69px, auto, 54px, 30px);
  }
  35% {
    clip: rect(98px, auto, 71px, 30px);
  }
  40% {
    clip: rect(146px, auto, 34px, 30px);
  }
  45% {
    clip: rect(134px, auto, 43px, 30px);
  }
  50% {
    clip: rect(102px, auto, 80px, 30px);
  }
  55% {
    clip: rect(119px, auto, 44px, 30px);
  }
  60% {
    clip: rect(106px, auto, 99px, 30px);
  }
  65% {
    clip: rect(141px, auto, 74px, 30px);
  }
  70% {
    clip: rect(20px, auto, 78px, 30px);
  }
  75% {
    clip: rect(133px, auto, 79px, 30px);
  }
  80% {
    clip: rect(78px, auto, 52px, 30px);
  }
  85% {
    clip: rect(35px, auto, 39px, 30px);
  }
  90% {
    clip: rect(67px, auto, 70px, 30px);
  }
  95% {
    clip: rect(71px, auto, 103px, 30px);
  }
  100% {
    clip: rect(83px, auto, 40px, 30px);
  }
}
@media screen and (max-width: 580px) {
  body .text.main-title-1 {
    font-size: 3rem;
  }
  body .text.bot-subtitle-1 {
    font-size: 1.1rem;
  }
  body .text.subtitle-3 {
    font-size: 1rem;
    font-weight: 400;
    margin: 5px;
  }
}
body a {
  text-decoration: none;
  color: #8c82ce;
  font-weight: bold;
}
body code {
  font-size: 1.1rem;
  padding: 5px 10px;
  background-color: var(--textCodeBackground) !important;
  border-radius: 8px;
  font-family: "Play", sans-serif;
}
body .right-text {
  text-align: right;
}
body .left-text {
  text-align: left;
}
body .center-text {
  text-align: center;
}
body .module-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
body .info-icon {
  display: flex;
  align-items: center;
  gap: 5px;
}
body .info-icon .icon {
  display: flex;
  align-items: center;
}
body .parallax {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  perspective: 10px;
}
@media screen and (max-width: 650px) {
  body .commission-calculator .title {
    text-align: center !important;
  }
}
body .commission-calculator .calculator {
  background-color: var(--backgroundColor02);
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  margin: 20px 0;
}
@media screen and (max-width: 650px) {
  body .commission-calculator .calculator {
    border-radius: 0;
  }
}
@media screen and (max-width: 800px) {
  body .commission-calculator .calculator .box-01 {
    grid-template-columns: 1fr !important;
  }
}
body .commission-calculator .calculator .box-01 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
body .commission-calculator .calculator .panel {
  border-radius: 10px;
  background-color: var(--backgroundColor03);
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
body .toggle-checkbox {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 10px;
}
body .toggle-checkbox input {
  display: none;
}
body .toggle-checkbox input:checked + .slider {
  background-color: #43b581;
}
body .toggle-checkbox input:checked + .slider .icon {
  opacity: 1;
}
body .toggle-checkbox .slider {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: var(--backgroundColor05);
  transition: 0.4s;
  border-radius: 3px;
}
body .toggle-checkbox .slider .icon {
  transition: 0.4s;
  opacity: 0;
  color: white;
  font-size: 1.2rem;
}
body .toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  margin: 0 10px;
}
body .toggle-switch input {
  display: none;
}
body .toggle-switch input:checked + .slider {
  background-color: #43b581;
}
body .toggle-switch input:checked + .slider::before {
  left: 32.5px;
}
body .toggle-switch .slider {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: var(--backgroundColor05);
  transition: 0.4s;
  border-radius: 50px;
}
body .toggle-switch .slider::before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 4.5px;
  bottom: 3.5px;
  background-color: white;
  border-radius: 50px;
  transition: 0.4s;
}
body .bubbles {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  z-index: -1;
  position: absolute;
}
body .bubbles .bubbles-01 {
  transform: translateZ(-10px) scale(2);
}
body .bubbles .bubbles-02 {
  transform: translateZ(-5px) scale(1.5);
}
body .bubbles .bubbles-01,
body .bubbles .bubbles-02 {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
}
body .creation-card {
  max-width: 250px;
  background-color: var(--backgroundColor03);
  color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  transition: 0.3s;
  position: relative;
  cursor: pointer;
}
body .creation-card:hover {
  transform: translateY(-5px);
}
body .creation-card .icon {
  position: absolute;
  z-index: 1;
  font-size: 27px;
  top: 10px;
  right: 10px;
  display: grid;
  gap: 5px;
}
body .creation-card .icon div {
  background-color: var(--backgroundColor02);
  padding: 6px;
  border-radius: 4px;
}
body .creation-card .price {
  width: 120px;
  margin: 0 auto;
  text-align: center;
  background-color: var(--backgroundColor02);
  border-radius: 15px;
  margin-top: 10px;
}
body .creation-card .image {
  margin: 0 auto;
  width: 120px;
  height: 120px;
  overflow: hidden; /* This will hide any parts of the image that exceed the container size */
  border-radius: 15px; /* This will make the container round */
  display: flex; /* This will center the image horizontally and vertically */
  justify-content: center;
  align-items: center;
  background-color: var(--backgroundColor02);
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}
body .creation-card .image img {
  -o-object-fit: cover;
     object-fit: cover; /* This will scale the image up or down to completely cover the container */
  -o-object-position: center;
     object-position: center; /* This will center the image within the container */
}
body .creation-card .image img.landscape {
  width: auto;
  height: 100%;
}
body .creation-card .image img.portrait {
  width: 100%;
  height: auto;
}
body .creation-card .details {
  padding: 1rem;
  padding-top: 0;
}
body .creation-card .details .name {
  font-family: "Archivo Black", sans-serif;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 5px;
}
body .creation-card .details .description {
  opacity: 0.8;
}
body .staff-card {
  max-width: 350px;
  background-color: var(--backgroundColor03);
  color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  transition: 0.3s;
  position: relative;
}
body .staff-card:hover {
  transform: translateY(-5px);
}
body .staff-card .connect {
  position: absolute;
  z-index: 1;
  font-size: 27px;
  background-color: var(--backgroundColor02);
  padding: 6px;
  top: 10px;
  right: 10px;
  border-radius: 4px;
}
body .staff-card .images {
  position: relative;
}
body .staff-card .images .banner {
  width: 350px;
  height: 140px;
  overflow: hidden; /* This will hide any parts of the image that exceed the container size */
  display: flex; /* This will center the image horizontally and vertically */
  justify-content: center;
  align-items: center;
}
body .staff-card .images .banner img {
  -o-object-fit: cover;
     object-fit: cover; /* This will scale the image up or down to completely cover the container */
  -o-object-position: center;
     object-position: center; /* This will center the image within the container */
  height: 100%;
  width: 100%;
}
body .staff-card .images .avatar {
  position: absolute;
  width: 120px;
  height: 120px;
  overflow: hidden; /* This will hide any parts of the image that exceed the container size */
  border-radius: 50%; /* This will make the container round */
  display: flex; /* This will center the image horizontally and vertically */
  justify-content: center;
  align-items: center;
  top: 50%;
  right: 8%;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}
body .staff-card .images .avatar img {
  -o-object-fit: cover;
     object-fit: cover; /* This will scale the image up or down to completely cover the container */
  -o-object-position: center;
     object-position: center; /* This will center the image within the container */
  border-radius: 50%; /* This will make the image round */
  height: 100%;
  width: 100%;
}
body .staff-card .details {
  padding: 1rem;
}
body .staff-card .details .name {
  font-family: "Archivo Black", sans-serif;
  font-size: 1.7rem;
  line-height: 30px;
}
body .staff-card .details .username {
  opacity: 0.5;
}
body .staff-card .details .roles {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
}
body .staff-card .details .roles .role {
  background-color: var(--backgroundColor02);
  padding: 2px 12px;
  border-radius: 5px;
  cursor: default;
}
body .tooltip {
  background-color: black;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  max-width: 300px;
}
body .drop-shadow-01 {
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
body .button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
body .button button {
  display: inline-flex;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: 500;
  transition: 0.3s;
  cursor: pointer;
  overflow: hidden;
  color: white;
  width: -moz-fit-content;
  width: fit-content;
  border: 3px solid transparent;
}
body .button button.color-theme {
  color: var(--textColor01) !important;
}
body .button button.shadow {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
}
body .button button.lock-black {
  color: black;
}
body .button button.disabled {
  opacity: 0.5;
  pointer-events: none;
}
body .button button.full {
  width: 100%;
  justify-content: center;
}
body .button button.color-link {
  padding: 0;
}
body .button button.color-link .button-text,
body .button button.color-link .button-icon {
  padding: 10px 5px;
}
body .button button.color-primary {
  background-color: #776aca;
}
body .button button.color-secondary {
  background-color: #4f545c;
}
body .button button.color-success {
  background-color: #3ba55d;
}
body .button button.color-danger {
  background-color: #f04747;
}
body .button button.color-blurple {
  background-color: #5865f2;
}
body .button button.color-gold {
  background-color: #df9d23;
}
body .button button .button-text,
body .button button .button-icon {
  display: inline-flex;
  align-items: center;
  padding: 7px 13px;
  height: 100%;
}
body .button button .button-text.both,
body .button button .button-icon.both {
  padding-right: 0;
}
body .button button .button-text.both-1,
body .button button .button-icon.both-1 {
  padding-left: 0;
}
body .button button .button-icon {
  font-size: 1.5rem;
}
body .button button .button-icon.small {
  font-size: 1.3rem;
}
body .button button.hover03 {
  color: var(--textColor01);
  background-color: transparent;
}
body .button button.hover03.color-primary {
  border-color: #776aca;
}
body .button button.hover03.color-secondary {
  border-color: #4f545c;
}
body .button button.hover03.color-success {
  border-color: #3ba55d;
}
body .button button.hover03.color-danger {
  border-color: #f04747;
}
body .button button.hover03.color-blurple {
  border-color: #5865f2;
}
body .button button.hover03.color-gold {
  border-color: #df9d23;
}
body .button button:hover.color-link {
  border-bottom: 1px solid #ffffff;
  border-radius: 0;
}
body .button button:hover.hover01 {
  color: var(--textColor01);
  background-color: transparent;
}
body .button button:hover.hover01.full {
  width: calc(100% - 5px) !important;
  height: calc(100% - 3px) !important;
}
body .button button:hover.hover01.color-primary {
  border-color: #776aca;
}
body .button button:hover.hover01.color-secondary {
  border-color: #4f545c;
}
body .button button:hover.hover01.color-success {
  border-color: #3ba55d;
}
body .button button:hover.hover01.color-danger {
  border-color: #f04747;
}
body .button button:hover.hover01.color-blurple {
  border-color: #5865f2;
}
body .button button:hover.hover01.color-gold {
  border-color: #df9d23;
}
body .button button:hover.hover02.color-primary {
  background-color: #6657c3;
}
body .button button:hover.hover02.color-secondary {
  background-color: #373b41;
}
body .button button:hover.hover02.color-success {
  background-color: #2e7f48;
}
body .button button:hover.hover02.color-danger {
  background-color: #d91212;
}
body .button button:hover.hover02.color-blurple {
  background-color: #404ff0;
}
body .button button:hover.hover02.color-gold {
  background-color: #cb8e1e;
}
body .button button:hover.hover03 {
  color: white;
}
body .button button:hover.hover03.color-primary {
  background-color: #776aca;
}
body .button button:hover.hover03.color-secondary {
  background-color: #4f545c;
}
body .button button:hover.hover03.color-success {
  background-color: #3ba55d;
}
body .button button:hover.hover03.color-danger {
  background-color: #f04747;
}
body .button button:hover.hover03.color-blurple {
  background-color: #5865f2;
}
body .button button:hover.hover03.color-gold {
  background-color: #df9d23;
}
body .footer {
  position: relative;
  width: 100%;
  background-color: #1E1E2E;
  border-radius: 8px 8px 0 0;
  transition: 0.5s;
  margin-top: auto;
}
body .footer .footer-container {
  max-width: 1540px;
  padding: 30px 40px;
  margin: auto;
}
body .footer .content-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
body .footer .content-footer .profil .logo-area {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
body .footer .content-footer .profil .logo-area img {
  width: 40px;
  height: 40px;
}
body .footer .content-footer .profil .logo-area .logo-name {
  font-size: 1.35rem;
  font-family: "Bungee", sans-serif;
  margin: 0 1rem;
  color: #ffffff;
}
body .footer .content-footer .profil .logo-area .theme-toggle {
  opacity: 0;
  position: absolute;
}
body .footer .content-footer .profil .logo-area .theme-toggle:checked + .theme-toggle-label .ball {
  transform: translateX(24px);
}
body .footer .content-footer .profil .logo-area .theme-toggle-label {
  position: relative;
  display: flex;
  width: 50px;
  height: 26px;
  background-color: #000000;
  border-radius: 25px;
  padding: 5px;
  align-items: center;
  justify-content: space-between;
}
body .footer .content-footer .profil .logo-area .theme-toggle-label .moon {
  color: #ffec81;
}
body .footer .content-footer .profil .logo-area .theme-toggle-label .sun {
  color: #fa8e31;
}
body .footer .content-footer .profil .logo-area .theme-toggle-label .ball {
  position: absolute;
  background-color: #ffffff;
  width: 22px;
  height: 22px;
  border-radius: 25px;
  top: 2px;
  left: 2px;
  transition: 0.3s;
}
body .footer .content-footer .profil .logo-area .theme-toggle-label:hover {
  cursor: pointer;
}
body .footer .content-footer .profil .description-area {
  max-width: 320px;
  margin-bottom: 1.25rem;
}
body .footer .content-footer .profil .description-area p {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  line-height: 2rem;
}
body .footer .content-footer .profil .social-media a {
  color: rgba(255, 255, 255, 0.75);
  margin-right: 22px;
  font-size: 24px;
  transition: 0.3s;
}
body .footer .content-footer .profil .social-media a:hover {
  color: #ceb9ff;
}
body .footer .content-footer .service-area {
  display: flex;
}
body .footer .content-footer .service-area .service-header {
  margin-right: 40px;
  list-style: none;
}
body .footer .content-footer .service-area .service-header .service-name {
  color: #ffffff;
  font-family: "Archivo Black", sans-serif;
}
body .footer .content-footer .service-area .service-header li {
  margin-bottom: 15px;
}
body .footer .content-footer .service-area .service-header li.disabled {
  opacity: 0.5;
  pointer-events: none;
}
body .footer .content-footer .service-area .service-header li a {
  color: rgba(255, 255, 255, 0.75);
  padding-bottom: 1px;
  text-decoration: none;
  transition: 0.3s;
  font-weight: 400;
}
body .footer .content-footer .service-area .service-header li a:hover {
  color: #ceb9ff;
}
body .footer hr {
  height: 1px;
  border: none;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 0 2rem;
  margin-bottom: 1rem;
}
body .footer .footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
}
body .footer .footer-bottom .copyright {
  color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
}
body .footer .footer-bottom .copyright span {
  margin-left: 8px;
  letter-spacing: 0.1rem;
}
body .footer .footer-bottom .tou a {
  color: rgba(255, 255, 255, 0.75);
  margin-left: 1.5rem;
  text-decoration: none;
  transition: 0.3s;
  font-weight: 400;
}
body .footer .footer-bottom .tou a:hover {
  color: #ceb9ff;
}
@media screen and (max-width: 768px) {
  body .footer .content-footer {
    flex-wrap: wrap;
    justify-content: left;
  }
  body .footer .service-area {
    flex-wrap: wrap;
    justify-content: left;
  }
  body .footer .service-header {
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 40px;
  }
  body .footer .social-media {
    margin-bottom: 1rem;
  }
  body .footer .footer-bottom {
    flex-wrap: wrap;
    justify-content: center;
  }
  body .footer .footer-bottom .copyright {
    margin-bottom: 15px;
  }
}/*# sourceMappingURL=index.style.css.map */