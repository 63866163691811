@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./exports/external.style";
@import "./exports/variable.style";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #0e1218;
        border-radius: 0 8px 8px 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $colorPrimary;
        border-radius: 4px 8px 8px 4px;
    }
}

html {
    height: 100%;
}

body {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100vw;
    min-height: 100vh;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    background-color: var(--backgroundColor01) !important;
    transition: $transition03;

    #root {
        z-index: 1;
    }
    #portal {
        z-index: 2;
    }
    #loading-root {
        position: fixed;
        display: grid;
        place-items: center;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: -1;
        transition: $transition02;

        img {
            height: 150px;
            width: 150px;
        }
    }
    &.load {
        overflow: hidden;
        #root {
            pointer-events: none !important;
        }
        #portal {
            pointer-events: none !important;
        }
        #loading-root {
            pointer-events: none !important;
            opacity: 1;
            z-index: 999;
            img {
                visibility: visible !important;
            }
        }
    }

    @import "./exports/text.style";
    @import "./exports/component.style";
}
