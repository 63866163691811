$colorPrimary: #8c82ce;
$colorSecondary: #ec8484;
$colorSuccess: #43b581;
$colorDanger: #f04747;

$colorPurple01: #ceb9ff;
$colorBlue01: #8c92e0;
$colorOrange01: #fa8e31;
$colorYellow01: #ffec81;
$colorYellow02: #fce03a;
$colorBlack: #000000;
$colorWhite: #ffffff;

$backgroundColor01: #11111B;
$backgroundColor02: #1E1E2E;
$backgroundColor03: #313149;

$textColor01: #ffffff;

$buttonColorPrimary: #776aca;
$buttonColorSecondary: #4f545c;
$buttonColorSuccess: #3ba55d;
$buttonColorDanger: #f04747;
$buttonColorBlurple: #5865f2;
$buttonColorGold: #df9d23;

$transition01: 0.1s;
$transition02: 0.3s;
$transition03: 0.5s;
$transition04: 0.7s;
$transition05: 0.9ms;

body {
    --backgroundColor01: #11111B;
    --backgroundColor02: #1E1E2E;
    --backgroundColor03: #313149;
    --backgroundColor04: #dadada;
    --backgroundColor05: #605052;
    --backgroundColor06: #1418207c;
    --tableBackgroundColor01: #141820;

    --textColor01: #ffffff;
    --textColorFaded01: #ffffff88;
    --textColor02: #000000;
    --textCodeBackground: #0c0e13;
    --textError01: #ffb3b3;
}
